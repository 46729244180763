@font-face {
  font-family: 'PixelOperator';
  src: url('../src/fonts/pixel_operator/PixelOperator.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PixelOperator-Bold';
  src: url('../src/fonts/pixel_operator/PixelOperator-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PixelOperator8-Bold';
  src: url('../src/fonts/pixel_operator/PixelOperator-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0px;
  padding: 0px;
  /* overflow: hidden; */

  font-family: 'PixelOperator8-Bold', 'Roboto', sans-serif;
  /* font-family: 'PixelOperator', 'Roboto', sans-serif; */
  /* 
  pointer-events: none; */
  /* cursor: crosshair; */
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  /* border: 1px dashed lime;  */
}

body {
  touch-action: none;
  overflow: hidden;
  height: 100vh;
}

.top {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed lime;
}

#cursorNormal {
  cursor: normal;
}
#cursorCrosshair {
  cursor: crosshair;
}

#react-parent {
  /* background-color: rgba(102, 154, 102, 0.381); */
  position: absolute;
  top: 4vw;
  left: 0px;
  width: 100%;
  height: calc(80% - 8vw);
  z-index: 0;

  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  /* justify-content: space-around; */
  align-items: center;

  flex-wrap: wrap;

  /* pointer-events: none; */
  /* cursor: crosshair; */
  cursor: grab;
  overflow: hidden;

  z-index: 1;
  /* border: 10px dashed lime; */
}

.game-parent {
  /* background-color: rgba(255, 0, 0, 0.135); */
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 0;

  overflow: hidden;
  /* cursor: grab; */
  /* cursor: crosshair; */
}

.project {
  position: relative;
  color: white;
  width: auto;
  height: auto;
  margin-left: 10vw;
  margin-right: 10vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* overflow: hidden; */
  /* border: 5px solid #3399ff; */
  border: 5px solid #115599;
  transition: all 0.2s ease-in-out;
  transform-origin: 50% 0%;
}

.project:hover {
  border: 5px solid white;
  /* border: 5px solid transparent; */

  /* transform: scale(1.1); */
  transition: all 0.1s ease-in-out;
}

.project:hover,
.project:hover .project-overlay,
.project:hover .project-video,
.project:hover .project-type,
.project:hover .project-title {
  color: transparent;
  background-color: transparent;

  text-shadow: 0px 0px 5px transparent;

  transition: all 0.1s ease-in-out;
  /* border: 5px solid transparent; */
  /* border: 5px solid rgb(245, 172, 24); */
}

.project-touch {
  border: 5px solid white;
  /* border: 5px solid rgb(245, 172, 24); */
  /* pointer-events: none; */
}

.project-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background-color: #3399ff; */
  /* background-color: rgba(0, 0, 0, 0); */
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #11559977;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 2;
}
.project-overlay-touch {
  background-color: rgba(0, 0, 0, 0);
}

.project-title {
  /* font-size: 2.5em; */
  font-size: clamp(3vw, 5vw, 100vw);
}

.project-type {
  font-size: clamp(3vw, 2vw, 100vw);
}
.color-trans {
  color: transparent;
}
.color-dark {
  color: black;
  text-shadow: 0px 0px 5px white;
}
.color-light {
  color: #caebff;
  text-shadow: black 0px 0px 5px;
}

.border-dark {
  border: 5px solid black;
}
.border-light {
  border: 5px solid white;
}

.project-video {
  position: relative;
  width: 20vw;
  z-index: 1;
  pointer-events: none;
  /* border: 5px dashed blue; */
}

.grass {
  position: absolute;
  bottom: 0%;
  left: 0px;
  width: 100%;
  height: 20%;
  background: url(../public/grass_05.png);
  background-repeat: repeat-x;
  pointer-events: none;
}

.computer {
  background-size: contain;
}

.phone {
  background-size: cover;
}

.progress-bar {
  position: absolute;
  bottom: -65px;
  /* top: 0px; */
  left: -30%;
  width: 160%;
  height: auto;
  margin-bottom: 3%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  z-index: 2;
}

.progress-bar-text {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  /* color: black; */

  /* // prevent cursor from selecting text */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */
  overflow: hidden;
}

.nav-notif {
  position: absolute;

  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  z-index: 1;
}

.nav-kirby {
  width: 8vh;
}

.nav-notif-text-small {
  position: relative;
  /* width: 100%; */
  font-size: 5vw;
  font-weight: bold;
  color: white;

  margin-top: 1.5vh;
  margin-bottom: -1vh;
  pointer-events: none;
}
.nav-notif-text-big {
  position: relative;
  /* width: 100%; */
  font-size: 15vw;
  font-weight: bold;
  color: white;

  pointer-events: none;
}

.states {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.project-resume {
  position: absolute;
  bottom: 1%;

  width: 100%;
  /* height: 5%; */
  background-color: #582a0b00;
  border: 5px solid #582a0b00;

  /* padding-bottom: 1%; */
  /* padding-top: 0.6%; */
  /* border: 5px solid #115599; */

  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: larger;

  /* // prevent cursor from selecting text */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */
  pointer-events: all;
  cursor: pointer;
  z-index: 1;
}

.project-resume-text:hover {
  background-color: rgba(64, 36, 25);

  color: white;
}

.project-resume-text {
  font-size: clamp(3vw, 2vw, 100vw);
  padding: 0.5vw 3vw;
  color: rgb(192, 127, 101);
}

.clicks {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #0000008f;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  z-index: 1;
}

@keyframes blink {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.clicks-text {
  font-size: 2em;
  color: white;
  animation: ease-in-out 1s infinite;
  animation-name: blink;
  text-shadow: 0px 0px 5px black;
}
.clicks-text-last {
  margin-bottom: -15%;
}

.clicks-text-mid {
  font-size: 3em;
  color: white;
  margin-bottom: 15%;
  /* animation: cubic-bezier(1, 0, 0, 1) 1s infinite; */
  animation: ease-in-out 1s infinite;
  animation-name: blink;
  text-shadow: 0px 0px 5px black;
}

.loader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #000000ff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  z-index: 1;
}

.transparent-layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: grab;
  z-index: 1;
}

.disable-hover {
  pointer-events: none;
}
